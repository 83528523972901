import * as validator from 'email-validator';
import {AjaxSync} from "./shared/ajax_utilities";
import {getFilterParam} from "./shared/common";

$(function() {

    var lookup = false;
    var $i_clear_multi = [];
    const container = ( app.DOM.hasOwnProperty('form') ) ? 'form' : 'filter';
    const tbl = 'usr';
    const name = app.TBL[tbl].i;
    const f = 'user';

    // cache dom elements
    app.DOM.user_id = app.DOM[container].find('#'+f+'_id');
    app.DOM.user_name = app.DOM[container].find('#'+f+'_name').addClass('ui-autocomplete-input');
    
    // add btn clear and cache
    const html = `
    <i class="btn-autocomplete-clear btn-autocomplete-clear-${tbl} icon fa fa-times"></i>
    <i class="autocomplete-loading autocomplete-loading-${tbl} icon fad fa-spin fa-spinner-third"></i>
    <i class="autocomplete-search autocomplete-search-${tbl} icon fa fa-search"></i>`;
    const containerClass = ( app.OPTIONS.autocomplete_usr_multi ) ? 'autocomplete-container autocomplete-container-multi' : 'autocomplete-container';

    // add container class
    app.DOM.user_name.after(html).parent().addClass(containerClass);

    const $i_clear  = app.DOM[container].find(`.btn-autocomplete-clear-${tbl}`);
    const $i_search = app.DOM[container].find(`.autocomplete-search-${tbl}`);
    const $i_loading = app.DOM[container].find(`.autocomplete-loading-${tbl}`);

    function showClear()
    {
        $i_clear.show();
        $i_loading.hide();
        $i_search.hide();
    }

    function showLoading()
    {
        $i_clear.hide();
        $i_loading.show();
        $i_search.hide();
        app.DOM.user_name.prop('readonly', true);
    }

    function showSearch()
    {
        $i_clear.hide();
        $i_loading.hide();
        $i_search.show();
        app.DOM.user_name.prop('readonly', false);
    }

    function selectAutoMulti(item)
    {
        // clear selection
        app.DOM.user_name.val('');

        // check already added
        if( $i_clear_multi.filter(`[data="${tbl}_${item.value}"]`).length > 0 ){
            alert('This '+name +' has already been added');
            return false;
        }

        const multiHtml = `<span class="autocomplete-multi">
        <input type="hidden" name="${f}[${item.value}]">
        ${item.value} - ${item.label}
        <i class="fa fa-times btn-autocomplete-clear-multi-${tbl}" title="Remove selection" data="${tbl}_${item.value}"></i>
        </span>`;
                    
        // add html
        $i_clear.after(multiHtml);

        // re-cache
        setupMultiDelete();
        
        return true;
    }

    function checkMultiHasValues()
    {
        const $inputs = app.DOM.form.find(`input:hidden[name*="${f}["]`);

        if( $inputs.length ) {
          app.DOM.user_name.removeClass('required');
        } else {
          app.DOM.user_name.addClass('required');
        }
     }

    var selectAuto = function(e, ui){

        // prevent jquery default
        if( e ) {
            e.preventDefault();
        }

        // update selected user_id
        if( app.OPTIONS.autocomplete_usr_multi ) {
            
            if( selectAutoMulti(ui.item) === false ) {
                return;
            }
            
            showSearch();

            checkMultiHasValues();
            
        } else {
            
            // update dom
            app.DOM.user_id.val(ui.item.value).trigger('change');
            app.DOM.user_name.val(ui.item.label);
            
            // change buttons
            showClear();
            
            if( app.LIST && app.DOM.filter_submit ) {
                app.DOM.filter_submit.click();
            }
        }

        // mark as edited
        if( app.OPTIONS.form ) {
            app.DOM.user_id.parent().addClass('edit');
        }
    };
    
    function setupMultiDelete()
    {
        $i_clear_multi = app.DOM[container].find(`.btn-autocomplete-clear-multi-${tbl}`);
        
        $i_clear_multi.unbind().on('click', function(){
            if( app.OPTIONS.form && confirm("Remove?") === false ) {
                return;
            }
            
            $(this).closest('span.autocomplete-multi').remove();
            $i_clear_multi = app.DOM[container].find(`.btn-autocomplete-clear-multi-${tbl}`);


            checkMultiHasValues();
        });

        // check initial state
        if( app.FORM.action === 'edit' && (app.URI[1] !== 'msg' || app.DOM.form.find('#type').val() === 'usr') ) {
            checkMultiHasValues();
        }
    }
    
    function setupAutocomplete(result)
    {
        const minLength = ( result.length <= 10 ) ? 0 : 1;

        app.DOM.user_name.autocomplete({
            minLength: minLength,
            source: result,
            select: selectAuto,
            focus: function(e, ui){
                e.preventDefault();
            }
        });

        if( minLength === 0 ) {
            app.DOM.user_name.on('focus', function(){
                if(!this.value){
                    $(this).autocomplete('search', '');
                }
            })
        }
    }
    
    function getAjaxData()
    {
        let data = {
            tbl_ref: app.URI[1],
            type:  (app.OPTIONS.form ) ? 'form' : app.URI[1]
        };

        if( app.OPTIONS.dashboard ) {
            data.tbl_ref = app.OPTIONS.dashboard;
            data.type = 'dashboard';
        } else if( app.URI[1] === 'list' || app.URI[1] === 'planner' ) {
            data.tbl_ref = app.URI[2];
        }

        // references purposes
        if( $.isNumeric(app.URI[2]) ){
            data.id = app.URI[2];
        }
        
        if( app.URI[1] === tbl ) {            
            if( $.isNumeric(app.URI[2]) ){
                data.exclude_id = app.URI[2];
            }
        }
        
        return data;
    }
    
    // select user from uri
    function selectFromURI(json, id)
    {
        var index = false;
        
        $.each(json, function(k,v){
            if( v.value === id){
                index = k;
                return false;
            }
        });
        
        // do nothing
        if( index === false ) {
            return;
        }
        
        var data = {
            item: {
                value: json[index].value,
                label: json[index].label
            }
        };
        
        selectAuto(false, data);
    }

    function setupLookup(type)
    {
        if( type === 'focus' && !lookup ) {
            showLoading();
        }

        if(lookup) {
            return;
        }

        // lookup accounts
        AjaxSync({
            url: `${app.CACHE.URL_AJAX + tbl}/get`,
            method: 'POST',
            data: getAjaxData()
        }, {
            done: (res) => {
                if( app.DOM.user_name.val() && app.DOM.user_id.val() ) {
                    showClear();
                } else {
                    showSearch();
                }

                // success
                if( res.status && res.status === 'success' && res.data ) {
                    // mark lookup complete
                    lookup = true;
                    // init autocomplete
                    setupAutocomplete(res.data);

                    if( app.URI[2] === 'false' && getFilterParam(3, false) === 'usr' && $.isNumeric(getFilterParam(3)) ){

                        // admin/tbl/false/usr:123
                        selectFromURI(res.data, getFilterParam(3));

                    } else if( app.URI[2] === 'false' && getFilterParam(4, false) === 'usr' && $.isNumeric(getFilterParam(4)) ){

                        // admin/tbl/false/xxx/usr:123
                        selectFromURI(res.data, getFilterParam(4));

                    } else if( app.URI[2] === 'false' && app.URI[3] === 'usr' && $.isNumeric(4) ){

                        // admin/tbl/false/usr/123
                        selectFromURI(res.data, 4);
                    }
                }
            }
        });
    }
    
    // check if input already has saved input
    if( app.DOM.user_name.length === 1 ) {
        if( app.DOM.user_name.val() === '' ) {
            showSearch();
        } else {
            showClear();
        }
    }

    // load accounts from ajax
    app.DOM.user_name.on('focus', function(e) {

        setupLookup(e.type);
        
    }).on('blur', function(){
        
        // empty name if no account id selected
        if( app.DOM.user_id.val() === '' ){
            app.DOM.user_name.val('');
            showSearch();
        }

    }).on('keyup', function(e){
        if( this.value === '') {

            showSearch();

            // update account
            app.DOM.user_id.val('').trigger('change');

        } else {
            showClear();
        }
    })

    if (app.OPTIONS.autocomplete_usr_email) {
        app.DOM.user_name.on('keydown', function (e) {
            if (e.keyCode === 13) {
                // Enter key - add whatever's in the box as if it were a valid email
                if (validator.validate(this.value)) {
                    selectAuto(e, {item:{
                        value: this.value,
                        label: `Email: ${this.value}`,
                    }})
                    $(this).removeClass('error');
                } else {
                    e.preventDefault()
                    $(this).addClass('error');
                }
            } else {
                $(this).removeClass('error');
            }
        });
    }

    // press clear button
    $i_clear.on('click', function(){
        
        // confirm account
        if( app.DOM.user_name.hasClass('disabled') || ( app.OPTIONS.form && confirm("Remove?") === false ) ) {
            console.log('cant clear');
            return;
        }
        
        showSearch();

        app.DOM.user_id.val('').trigger('change');
        app.DOM.user_name.val('').focus();
        
        // autoclick
        if( app.URI[1] === 'list' ) {
            app.DOM.filter_submit.click();
        }
    });

    if( app.OPTIONS.autocomplete_usr_multi ) {
        setupMultiDelete();
    }

    // load ajax straight away as they will not often change parent account if on account form
    if( app.OPTIONS.form && ( (app.FORM.action === 'add' && app.OPTIONS.autocomplete_usr_preload !== false) || app.OPTIONS.autocomplete_usr_preload ) ) {
        setTimeout(function() {
            setupLookup('focus');
        }, 150);
    }
});